<template>
  <div>
    <!--
    <b-form-checkbox
        size="lg"
    ></b-form-checkbox>
    -->
  </div>
</template>

<script>
  export default {

  }
</script>